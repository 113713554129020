exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-directory-js": () => import("./../../../src/pages/directory.js" /* webpackChunkName: "component---src-pages-directory-js" */),
  "component---src-pages-documents-js": () => import("./../../../src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-facility-details-js": () => import("./../../../src/pages/facility-details.js" /* webpackChunkName: "component---src-pages-facility-details-js" */),
  "component---src-pages-forgot-js": () => import("./../../../src/pages/forgot.js" /* webpackChunkName: "component---src-pages-forgot-js" */),
  "component---src-pages-gallery-details-js": () => import("./../../../src/pages/gallery-details.js" /* webpackChunkName: "component---src-pages-gallery-details-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-details-js": () => import("./../../../src/pages/order-details.js" /* webpackChunkName: "component---src-pages-order-details-js" */),
  "component---src-pages-orders-js": () => import("./../../../src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-page-js": () => import("./../../../src/pages/page.js" /* webpackChunkName: "component---src-pages-page-js" */),
  "component---src-pages-poi-details-js": () => import("./../../../src/pages/poi-details.js" /* webpackChunkName: "component---src-pages-poi-details-js" */),
  "component---src-pages-poi-js": () => import("./../../../src/pages/poi.js" /* webpackChunkName: "component---src-pages-poi-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-refund-js": () => import("./../../../src/pages/refund.js" /* webpackChunkName: "component---src-pages-refund-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

